@charset "utf-8";
@import '~bootstrap/scss/bootstrap';
@import '~bulma-o-steps/bulma-steps.css';
@import '@aws-amplify/ui-react/styles.css';

$color-primary-blue: #3d569c;
$color-primary-purple: #81236c;
$color-secondary-teal: #31727c;
$color-secondary-orange: #e06e21;
$color-secondary-yellow: #dbc024;
$color-secondary-blue: #62b1c0;
$color-secondary-light: #dfeff2;
$color-secondary-darkpurple: #150724;
$color-black: #110912;

[data-amplify-authenticator] {
  height: 70vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// bulma extensions customizations
ul.steps {
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
    .steps-marker {
      z-index: 0;
    }
  }
}

// bootstrap customizations
.toast-body {
  background-color: rgba(255, 255, 255, 0.6);
}

// auth header
.logo-container {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 40px;
    padding: 5px;
  }
  &.navbar {
    padding: 0 20px;
  }
  .logo-name {
    font-size: 1.5rem;
  }
}
